import type { ComponentRef, EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MENUS_SHOWCASE_APP_DEF_ID, MENUS_SHOWCASE_BM_URL } from './consts';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import { MenusClient } from 'root/api/MenusClient';
import type { MenusOrder } from 'root/components/Menu/types';
import { getWidgetProps } from './commonUtils';

export const openManageMenuDashboardPanel = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  componentRef: ComponentRef
) => {
  const { data: menusFromServer } = await getMonitoredApiCall(
    () => MenusClient(flowAPI.essentials.httpClient).getAll({}),
    flowAPI.reportError
  );
  const widgetProps = await getWidgetProps(editorSDK, componentRef);
  const menusOrder = widgetProps?.menusOrder as MenusOrder;
  const menuId = menusOrder?.[0] || menusFromServer?.data?.[0]?.id;

  editorSDK.editor
    .openDashboardPanel(MENUS_SHOWCASE_APP_DEF_ID, {
      url: menuId ? `${MENUS_SHOWCASE_BM_URL}/${menuId}` : MENUS_SHOWCASE_BM_URL,
      closeOtherPanels: false,
    })
    .then(async () => {
      await editorSDK.document.application.livePreview.refresh('', {
        shouldFetchData: true,
        source: '',
      });
      await editorSDK.document.application.reloadManifest();
    });
};
